





import Vue from 'vue';

export default Vue.extend({
  'name': 'Home',
  'components': {
  },
  mounted () {
    if (this.$store.getters.navMenus && this.$store.getters.navMenus.length > 0) {
      this.$router.push({
        'name': this.$store.getters.navMenus[0].routeName
      });
    } else {
      this.$router.push({
        'name': 'Login'
      });
    }
  }
});
