








import Vue from 'vue';
import emptyLayout from '@/views/_layouts/empty.vue';
import layout from '@/views/_layouts/authenticated.vue';
import EventBus from '@/event-bus';
import { AuthLoginResponse } from './types/api/AuthLogin';
import { User } from './store/auth';

const emptyLayoutRoutes: Array<string> = [
  'Login',
  'DealerLogin',
  'ForgotPassword',
  'CreatePassword',
  'CustomerLanding',
  'CustomerWarranty'
];

export default Vue.extend({
  'name': 'Main',
  'watch': {
    $route (to) {
      this.$store.commit(
        'setTitle',
        to.meta ? (to.meta.title ? to.meta.title : '') : ''
      );
    }
  },
  'components': {
    emptyLayout,
    layout
  },
  'computed': {
    currentLayout () {
      // return publicLayout;
      if (emptyLayoutRoutes.includes(this.$route.name as string)) {
        return emptyLayout;
      }

      return layout;
    }
  },
  created () {
    EventBus.$on('unauthorized', () => {
      this.$store.commit('resetAuthStatus');
      if (this.$route.name !== 'Login') {
        this.$router.push({
          'name': 'Login'
        });
      }
    });

    this.axios.get('check-login-status').then(({ data }) => {
      const response = data as AuthLoginResponse;

      this.$store.commit('setAuthenticationStatus', {
        'token': response.result.token,
        'username': '',
        'userId': response.result.id,
        'role': response.result.role
      } as User);

      this.$store.commit('resetAppState');
    });
  }
});
